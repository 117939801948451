import React, { useState } from "react";
import { Link } from 'gatsby';


import "./FooterLink.scss"
import "./AdmLog.scss"

const AdmLog = () => {

  return (
    <div className="footer__log">
      <Link className="footer__link footer__link--log" to="/admin">
          adm
      </Link>
    </div>
  )
}

export default AdmLog;

