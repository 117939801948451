import React from "react"
import { Link } from "gatsby"

import "./FooterLink.scss"

const FooterLink = ({link, text}) => (
  <Link className="footer__link" to={link}>
    {text}
  </Link>
)

export default FooterLink