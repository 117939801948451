import React, { useState, useEffect } from "react"
//import isBrowser from '../../accessors';
import { isBrowser } from "../../accessors"

import "./Buttons.scss"

const BackTopBtn = () => {
  const [visible, onVisible] = useState(true)

  const handleClick = () => {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  }

  useEffect(() => {
    isBrowser() &&
      window.addEventListener("scroll", () => handleScroll(visible))

    return () =>
      window.removeEventListener("scroll", () => handleScroll(visible))
  })

  const handleScroll = visibility => {
    window.scrollY > 500 && visibility === true && onVisible(false)
    window.scrollY <= 500 && visibility === false && onVisible(true)
  }

  return (
    <>
      <button
        onClick={handleClick}
        className={
          "button__back-top" + (visible ? "" : " button__back-top--active")
        }
      >
        <div className="arrow__top"></div>
      </button>
    </>
  )
}

export default BackTopBtn
